import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import vomozLogo from "../../../assets/images/vomoz/ball.png"
import CoverPictureModal from "./CoverPictureModal"
import ProfilePictureModal from "./ProfilePictureModal"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const ProfilePicture = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(null)
  const [unlock, setUnlock] = useState(false)
  const [profilePicture, setProfilePicture] = useState("")
  const [coverPicture, setCoverPicture] = useState("")
  const [loadProfilePicture, setLoadProfilePicture] = useState(false)
  const [loadCoverPicture, setLoadCoverPicture] = useState(false)

  const getUserParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(null)
      setProfilePicture(userParameters.profilePicture)
      setCoverPicture(userParameters.coverPicture)
      setLoadProfilePicture(true)
      setLoadCoverPicture(true)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  const toggleModal = () => {
    setOpen(!open)
  }

  const toggleCoverModal = () => {
    setUnlock(!unlock)
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          // timeout={2000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={vomozLogo} />
        </SweetAlert>
      )}
      <Card className="overflow-hidden">
        {loadProfilePicture ? (
          <ProfilePictureModal
            isOpen={open}
            toggle={toggleModal}
            refreshData={getUserParameters}
            profilePhoto={profilePicture}
          />
        ) : null}
        {loadCoverPicture ? (
          <CoverPictureModal
            isOpen={unlock}
            toggle={toggleCoverModal}
            refreshData={getUserParameters}
            coverPhoto={coverPicture}
          />
        ) : null}
        <div className="bg-primary">
          <Row>
            <Col>
              <div className="text-white p-4">
                <p>Profile Photos</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-2 px-0 ">
          {loading ? (
            <div className="center-div">
              <div>Loading...</div>
            </div>
          ) : (
            <div className="space-around mobile-column-flex">
              <div className="column-flex mb-4">
                <div className="pt-2">
                  <p>Edit profile picture</p>
                </div>

                <button
                  className="img-btn "
                  type="button"
                  onClick={toggleModal}
                >
                  <img
                    src={profilePicture}
                    alt="Profile picture"
                    className="img-thumbnail rounded-circle avatar-xl "
                  />
                </button>
              </div>
              <div className="column-flex mb-4">
                <div className="pt-2 ">
                  <p>Edit cover picture</p>
                </div>

                <button
                  className="img-btn"
                  type="button"
                  onClick={toggleCoverModal}
                >
                  <img
                    src={coverPicture}
                    alt="Cover picture"
                    className="img-thumbnail rounded-circle avatar-xl "
                  />
                </button>
              </div>
            </div>
          )}
          <div className="mt-5 center-div">
            <Button style={{ width: "45%" }} type="button" color="link">
              <Link to="/">Cancel</Link>
            </Button>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ProfilePicture.propTypes = {
  t: PropTypes.any,
}

export default ProfilePicture
