// availity-reactstrap-validation
import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { Form, Formik } from "formik"
import { ORG_ID } from "helpers/constants"
import { resumeLogin, userAccountParameters } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import logoImg from "../../assets/images/logo.svg"
// import images
import profileImg from "../../assets/images/profile-img.png"
import externalLinks from "../../helpers/externalLinks"

const user = JSON.parse(localStorage.getItem("userData")) || {}
const userGlobalId = user?.userGlobalId

const LockScreen = () => {
  const [loading, setLoading] = useState(null)
  const [userParams, setUserParams] = useState(null)
  const [passwordType, setPasswordType] = useState("password")
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })

  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  const getUserAccountParameters = async () => {
    const data = {
      orgId: ORG_ID,
      userGlobalId,
    }
    const response = await userAccountParameters(data)
    const userAccountParams = response?.data
    if (userAccountParams) setUserParams(userAccountParams)
  }

  useEffect(() => {
    getUserAccountParameters()
  }, [])

  return (
    <React.Fragment>
      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Lock screen</h5>
                        <p>
                          Your session was locked due to inactivity. Enter your
                          password to continue.
                        </p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <a href={externalLinks.vomozFlexLandingPage}>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={{
                        password: "",
                      }}
                      validationSchema={Yup.object({
                        password: Yup.string()
                          .min(6, "Must be 6 characters or more")
                          .required("Required"),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        console.log(values)
                        setLoading(true)
                        const data = {
                          orgId: ORG_ID,
                          userGlobalId,
                          password: values.password,
                        }

                        if (values.password) {
                          const res = await resumeLogin(data)
                          resetForm({ values: "" })
                          if (res === undefined) {
                            alert("Please Check your internet connection")
                            setLoading(false)
                          }

                          if (res?.success === false) {
                            console.log(res)
                            setLoading(false)
                            setInfo({
                              confirm: false,
                              error_dlg: true,
                              dynamic_title: "Error!",
                              dynamic_description: res?.message,
                            })
                          } else if (res?.success === true) {
                            window.location.href = "/dashboard"
                          }
                        }
                      }}
                    >
                      {props => {
                        return (
                          <Form className="form-horizontal">
                            {userParams && (
                              <div className="user-thumb text-center mb-4">
                                <img
                                  src={userParams?.profilePicture}
                                  className="rounded-circle img-thumbnail avatar-md"
                                  alt="thumbnail"
                                />
                                <h5 className="font-size-15 mt-3">
                                  {userParams?.firstName +
                                    " " +
                                    userParams?.lastName}
                                </h5>
                              </div>
                            )}

                            <FormGroup>
                              <Label>Password</Label>
                              <InputGroup>
                                <input
                                  name="password"
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  type={passwordType}
                                  placeholder="Enter password"
                                  className="form-control mr-0 ml-0"
                                  onBlur={props.handleBlur}
                                />

                                <div
                                  className="input-group-append"
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePasswordType}
                                >
                                  <span className="input-group-text">
                                    {passwordType === "text" ? (
                                      <i class="fa fa-low-vision"></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </InputGroup>
                              {props.touched.password &&
                              props.errors.password ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.password}
                                </p>
                              ) : null}
                            </FormGroup>
                            <div className="form-group row mb-0">
                              <Col xs="12" className="text-right">
                                <Button
                                  color="primary"
                                  className="btn btn-block waves-effect waves-light mt-2"
                                  type="submit"
                                >
                                  {loading ? "Unlocking" : "Unlock"}
                                </Button>
                              </Col>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Not you? Return to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Sign In{" "}
                  </Link>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LockScreen
