const FooterMessage = () => {
  return (
    <footer>
      <p>
        Copyright © {new Date().getFullYear()} Vomoz Platforms | 4.1.1 (100324).
        All Rights Reserved.
      </p>
    </footer>
  )
}

export default FooterMessage
