import { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { Input, InputGroup } from "reactstrap"
import CountriesList from "./countries-list.json"

const SearchLocation = () => {
  const history = useHistory()
  const locationParam = useLocation()
  const [location, setLocation] = useState("")
  const [countries, setCountries] = useState([])
  const [menu, setMenu] = useState(false)

  const openMenu = () => {
    setMenu(true)
  }

  const closeMenu = () => {
    setMenu(false)
  }

  const handleChange = e => {
    let value = e.target.value
    setLocation(value)
    if (!menu) {
      openMenu()
    }
  }

  const handleEnterSubmit = event => {
    let key = event.which || event.keyCode
    console.log(countries.length)
    if (key === 13 && countries.length > 0) {
      // alert(`/rates/${countries[0]?.name}`)
      history.push(`/services/rates/${countries[0]?.name}`)
    }
    console.log(location)
  }
  useEffect(() => {
    document.addEventListener("keydown", handleEnterSubmit)
    return () => {
      document.removeEventListener("keydown", handleEnterSubmit)
    }
  })

  useEffect(() => {
    closeMenu()
  }, [locationParam.pathname])

  const resetInputs = () => {
    setLocation("")
    setCountries([])
  }
  useEffect(() => {
    if (location) {
      const searchStr = location.toLowerCase()
      const filteredData = CountriesList.filter(country =>
        country.name.toLowerCase().includes(searchStr)
      )

      setCountries(filteredData)
    }
  }, [location])

  return (
    <>
      <InputGroup>
        <Input
          onChange={handleChange}
          value={location}
          placeholder="Where would you like to call?"
        />
      </InputGroup>
      {countries && menu ? (
        <div
          style={{
            position: "fixed",
            marginTop: "10px",
            background: "white",
            width: "80vw",
            borderRadius: "5px",
          }}
        >
          {countries.map(country => (
            <Link
              style={{ color: "black" }}
              onClick={resetInputs}
              to={`/services/rates/${country.name}`}
              key={country.name}
            >
              <li style={{ listStyleType: "none" }}>
                <img
                  src={country.flag}
                  style={{ marginLeft: "-5px", width: "20px" }}
                />{" "}
                {country.name}
              </li>
            </Link>
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default SearchLocation
