import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Button } from "reactstrap"
//Simple bar
import SimpleBar from "simplebar-react"
import externalLinks from "../../helpers/externalLinks"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  logout() {
    console.log("Logging Out")
    localStorage.removeItem("userData")
    window.location.href = externalLinks.vomozFlexLandingPage
    return null
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }

    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{"Menu"}</li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="bx bx-home-circle" />

                  <span>{"Dashboard"}</span>
                </Link>
              </li>

              <li className="menu-title">{"Account"}</li>

              <li>
                <Link to="/account/auto-top-up" className=" waves-effect">
                  <i className="bx bx-purchase-tag" />
                  <span>{"Auto Top Up"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/buy-credit">
                  <i className="bx bx-credit-card" />
                  <span>{"Buy Call Credit"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/transfer-credit">
                  <i className="bx bx-transfer" />
                  <span>{"Transfer Call Credit"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/vomoz-card" className="waves-effect">
                  <i className="bx bx-gift" />
                  <span>{"Load Vomoz Card"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/call-log" className=" waves-effect">
                  <i className="bx bx-phone-call" />
                  <span>{"Call Log"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/local-numbers" className=" waves-effect">
                  <i className="bx bxs-contact" />
                  <span>{"Local Numbers"}</span>
                </Link>
              </li>

              <li>
                <Link to="/account/follow-me" className="waves-effect">
                  <i className="bx bx-map-pin" />
                  <span>{"Follow Me Service"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/caller-id" className="waves-effect">
                  <i className="bx bx-user" />
                  <span>{"Caller IDs"}</span>
                </Link>
              </li>
              <li>
                <Link to="/account/access-numbers" className="waves-effect">
                  <i className="bx bx-phone" />
                  <span>{"Access Numbers"}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/account/authorized-countries"
                  className="waves-effect"
                >
                  <i className="bx bx-globe" />
                  <span>{"Authorized Countries"}</span>
                </Link>
              </li>

              <li>
                <Link to="/account/purchase-history" className="waves-effect">
                  <i className="bx bx-history" />
                  <span>{"Purchase History"}</span>
                </Link>
              </li>

              <li className="menu-title">Services</li>
              <li>
                <Link to="/services/rates" className="waves-effect">
                  <i className="bx bx-user-circle" />
                  <span>{"Rates"}</span>
                </Link>
              </li>
              <li>
                <Link to="/services/contact" className=" waves-effect">
                  <i className="bx bx-support" />
                  <span>{"Contact Us"}</span>
                </Link>
              </li>
              <li>
                <Link to="/services/referral" className=" waves-effect">
                  <i className="bx bx-plus-circle" />
                  <span>{"Refer A Friend"}</span>
                </Link>
              </li>

              <li>
                <Link to="/services/partner" className="waves-effect">
                  <i className="bx bx-file" />
                  <span>{"Become A Partner"}</span>
                </Link>
              </li>
              <li>
                <Link to="/services/social" className="waves-effect">
                  <i className="bx bx-share-alt" />
                  <span>{"Follow Us"}</span>
                </Link>
              </li>

              <li className="menu-title">
                {/* <i className="bx bx-cog" /> */}
                {"Settings"}
              </li>

              <li>
                <Link to="/settings/user-settings" className="waves-effect">
                  <i className="bx bx-wrench" />
                  <span>{"User Settings"}</span>
                </Link>
              </li>

              <li>
                <Link to="/settings/show-pin" className="waves-effect">
                  <i className="bx bx-lock" />
                  <span>{"Show PIN"}</span>
                </Link>
              </li>

              <li>
                <Link to="/settings/profile-photos" className="waves-effect">
                  <i className="bx bx-camera" />
                  <span>{"Profile Photos"}</span>
                </Link>
              </li>

              <li>
                <Link to="/settings/session-timeout" className="waves-effect">
                  <i className="bx bx-timer" />
                  <span>{"Session Timeout"}</span>
                </Link>
              </li>

              <li>
                <Link to="/settings/delete-account" className="waves-effect">
                  <i className="bx bx-trash" />
                  <span>{"Delete Account"}</span>
                </Link>
              </li>
              <li>
                <Button
                  style={{ backgroundColor: "#2a3042", borderStyle: "none" }}
                  onClick={this.logout}
                  className="waves-effect ml-3 pl-2"
                >
                  <i className="bx bx-power-off font-size-20 align-middle pr-1 mr-2 text-danger" />
                  <span style={{ color: "#A6B0CF" }}>{"Logout"}</span>
                </Button>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  type: PropTypes.string,
}

export default withRouter(SidebarContent)
