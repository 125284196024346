import Breadcrumbs from "components/Common/Breadcrumb"
import { ORG_ID } from "helpers/constants"
import { userAccountParameters } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import ProfilePicture from "./ProfilePicture/ProfilePicture"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}
const ProfilePhotos = () => {
  const [loading, setLoading] = useState(null)
  const [initialValues, setInitialValues] = useState(null)

  const getUserParameters = async () => {
    setLoading(true)
    const data = {
      orgId: ORG_ID,
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(null)
      setInitialValues(userParameters)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            // timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Settings"} breadcrumbItem={"Profile Photos"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <ProfilePicture />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProfilePhotos
