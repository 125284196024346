import { Form, Formik } from "formik"
import { assignLocalNumber, availableLocalNumbers } from "helpers/requests"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import "react-phone-input-2/lib/style.css"
import Select from "react-select"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const AssignLocalNumber = props => {
  const { isOpen, toggle, refreshTable } = props
  const [loading, setLoading] = useState(null)
  const [options, setOptions] = useState([])
  const [localNumber, setLocalNumber] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const formikInitialValues = {
    localNumber: "",
    destination: "",
    nickname: "",
  }

  const onCaptchaChange = value => {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const getAvailableLocalNumbers = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    console.log(data)
    const response = await availableLocalNumbers(data)
    const availableNumbers = response?.data?.returnedRecords
    if (availableNumbers) {
      setOptions(
        availableNumbers.map(local => ({
          label: local.localNumber,
          value: local.id,
        }))
      )
    }
  }
  useEffect(() => {
    getAvailableLocalNumbers()
  }, [])

  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Assign Local Number</ModalHeader>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={Yup.object({
              localNumber: Yup.string().required("Required"),
              destination: Yup.string()
                .min(10, "Minimum of 10 digits")
                .max(20, "Maximum of 20 digits")
                .required("Required"),
              nickname: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(30, "Maximum of 30 characters"),
            })}
            onSubmit={async (values, { resetForm }) => {
              console.log(values)
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                id: values.localNumber,
                destination: values.destination,
                nickname: values.nickname,
              }

              const response = await assignLocalNumber(data)
              setLoading(false)
              if (response?.success) {
                setLoading(false)
                setInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Added",
                  dynamic_description: response?.message,
                })
                refreshTable()
                toggle()
              } else {
                setLoading(false)
                setInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed",
                  dynamic_description: response?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Local Number</Label>
                          <Select
                            onChange={value => {
                              setLocalNumber(value.label)
                              props.setFieldValue("localNumber", value.value)
                            }}
                            options={options}
                            classNamePrefix="select2-selection"
                            name="localNumber"
                            onBlur={() =>
                              props.setFieldTouched("localNumber", true)
                            }
                          />
                          {props.touched.localNumber &&
                          props.errors.localNumber ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.localNumber}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-destination-Input">
                            Destination
                          </Label>

                          <Input
                            type="text"
                            value={props.values.destination}
                            name="destination"
                            id="formrow-destination-Input"
                            className="form-control"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            placeholder="Enter destination number"
                          />
                          {props.touched.destination &&
                          props.errors.destination ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.destination}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-name-Input">Nickname</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-name-Input"
                            placeholder={"Enter your nickname"}
                            name="nickname"
                            onChange={props.handleChange}
                            values={props.values.nickname}
                            onBlur={props.handleBlur}
                          />
                          {props.touched.nickname && props.errors.nickname ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.nickname}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>{" "}
                      <Col md={12}>
                        <FormGroup
                          className="select2-container"
                          style={{ maxWidth: "50%" }}
                        >
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                          disabled={!disableButton}
                        >
                          {loading ? "Sending..." : "Send"}
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <br />
                      <i
                        style={{
                          color: "#000",
                          fontSize: "11px",
                          marginLeft: "10px",
                        }}
                      >
                        Kindly check the captcha checkbox to enable this "Send"
                        button
                      </i>
                    </Row>
                  </ModalBody>
                  <ModalFooter></ModalFooter>
                  {console.log(props.values)}
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

AssignLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignLocalNumber
