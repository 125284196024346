import profileImg from "assets/images/profile-img.svg"
import Breadcrumbs from "components/Common/Breadcrumb"
import { ORG_ID } from "helpers/constants"
import externalLinks from "helpers/externalLinks"
import { userAccountParameters } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import SessionTimeoutModal from "./SessionTimeoutModal"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}
const SessionTimeout = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(null)
  const [initialValues, setInitialValues] = useState(null)

  const toggleModal = () => setOpen(!open)

  const getUserParameters = async () => {
    setLoading(true)
    const data = {
      orgId: ORG_ID,
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(null)
      setInitialValues(userParameters)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            // timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <SessionTimeoutModal
          isOpen={open}
          toggle={toggleModal}
          getUserParameters={getUserParameters}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Settings"} breadcrumbItem={"Session Time Out"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Session Time Out</h5>
                        {/* <p>Change Security PIN</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <h5>Your Session Time Out Duration</h5>
                    <p>
                      {loading
                        ? "Loading..."
                        : `${
                            initialValues?.sessionTimeoutDuration / 10
                          } minutes`}
                    </p>

                    <div className="mt-4">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="button"
                        onClick={toggleModal}
                      >
                        Edit your Session Time Out
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        By visiting this page, you agree to Vomoz's{" "}
                        <a
                          href={externalLinks.vomozFlexLandingPagePrivacyPolicy}
                          className="text-primary"
                        >
                          Terms of Use
                        </a>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SessionTimeout
