import { Form, Formik } from "formik"
import { ORG_ID } from "helpers/constants"
import { systemSettings, updateSessionTimeoutDuration } from "helpers/requests"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"

const user = JSON.parse(localStorage.getItem("userData")) || {}
const userGlobalId = user?.userGlobalId

const SessionTimeoutModal = props => {
  const { isOpen, toggle, getUserParameters } = props
  const [loading, setLoading] = useState(null)
  const [options, setOptions] = useState("")
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })

  const getSessionTimoutDuration = async () => {
    const data = {
      orgId: ORG_ID,
    }
    const response = await systemSettings(data)
    const sessionTimeoutDuration = response?.data?.sessionTimeoutDuration
    console.log("sessionTimeoutDuration", sessionTimeoutDuration)
    if (sessionTimeoutDuration) {
      setOptions(
        sessionTimeoutDuration.map(time => ({
          label: time.label,
          value: time.seconds,
        }))
      )
    }
  }

  useEffect(() => {
    getSessionTimoutDuration()
  }, [])

  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>
            Edit Session Timeout Duration
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col lg={12}>
                <br />
                <Formik
                  initialValues={{
                    newSessionTimeoutDuration: "",
                  }}
                  validationSchema={Yup.object({
                    newSessionTimeoutDuration:
                      Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    setLoading(true)
                    const data = {
                      orgId: ORG_ID,
                      userGlobalId,
                      newSessionTimeoutDuration:
                        values.newSessionTimeoutDuration,
                    }
                    if (values.newSessionTimeoutDuration) {
                      const res = await updateSessionTimeoutDuration(data)
                      resetForm({ values: "" })
                      setLoading(null)
                      if (res === undefined) {
                        alert("Please check your internet connection")
                        setLoading(null)
                      }
                      if (res?.success === false) {
                        setLoading(null)
                        setInfo({
                          confirm: false,
                          error_dlg: true,
                          dynamic_title: "Failed!",
                          dynamic_description: res?.message,
                        })
                      } else if (res?.success === true) {
                        setLoading(null)
                        getUserParameters()
                        setInfo({
                          confirm: false,
                          success_dlg: true,
                          dynamic_title: "Success!",
                          dynamic_description: res?.message,
                        })
                        toggle()
                      }
                    }
                  }}
                >
                  {props => {
                    return (
                      <Form className={"justify-content-center"}>
                        <FormGroup className="row mb-4">
                          <Col sm={2}></Col>
                          <Col sm={8}>
                            <Label
                              for="horizontal-firstname-Input"
                              className="col-sm-12 col-form-Label"
                            >
                              Session Timeout Duration
                            </Label>
                            <Select
                              name="newSessionTimeoutDuration"
                              options={options}
                              classNamePrefix="select2-selection"
                              onChange={value => {
                                props.setFieldValue(
                                  "newSessionTimeoutDuration",
                                  value.value
                                )
                              }}
                              onBlur={() =>
                                props.setFieldTouched(
                                  "newSessionTimeoutDuration",
                                  true
                                )
                              }
                            />
                            {props.touched.newSessionTimeoutDuration &&
                            props.errors.newSessionTimeoutDuration ? (
                              <p style={{ color: "red", fontSize: "11px" }}>
                                {props.errors.newSessionTimeoutDuration}
                              </p>
                            ) : null}
                          </Col>
                          <Col sm={2}></Col>
                        </FormGroup>
                        <Row>
                          <Col sm={2}></Col>
                          <Col sm={8}>
                            <FormGroup className="row justify-content-center">
                              <Button
                                type="submit"
                                color="primary"
                                className="btn btn-primary btn-block waves-effect waves-light"
                              >
                                {loading ? "Loading..." : "Load"}
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col sm={2}></Col>
                        </Row>
                      </Form>
                    )
                  }}
                </Formik>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="danger" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

SessionTimeoutModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default SessionTimeoutModal
