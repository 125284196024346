import Axios, { config, configMultiPart } from "./axios"

export const sendEmail = async emailData => {
  try {
    const response = await Axios.post("send-email/", { ...emailData }, config)
    const data = response.data
    localStorage.setItem("sendEmail", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const joinNewsletter = async newsLetterData => {
  try {
    const response = await Axios.post(
      "join-newsletter/",
      { ...newsLetterData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const unSubscribe = async unSubscribeData => {
  try {
    const response = await Axios.post(
      "unsubscribe/",
      { ...unSubscribeData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const localNumbersAssigned = async localNumbersAssignedData => {
  try {
    const response = await Axios.post(
      "local-numbers-assigned/",
      { ...localNumbersAssignedData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const availableLocalNumbers = async availableLocalNumbersData => {
  try {
    const response = await Axios.post(
      "available-local-numbers/",
      { ...availableLocalNumbersData },
      config
    )
    const data = response.data
    localStorage.setItem("availableLocalNumbers", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const assignLocalNumber = async assignLocalNumberData => {
  try {
    const response = await Axios.post(
      "assign-local-number/",
      { ...assignLocalNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const editLocalNumber = async editLocalNumberData => {
  try {
    const response = await Axios.post(
      "edit-local-number/",
      { ...editLocalNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteLocalNumber = async deleteLocalNumberData => {
  try {
    const response = await Axios.post(
      "delete-local-number/",
      { ...deleteLocalNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const callerIdsVerified = async callerIdsVerifiedData => {
  try {
    const response = await Axios.post(
      "callerids-verified/",
      { ...callerIdsVerifiedData },
      config
    )
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const callLogs = async callsLogData => {
  try {
    const response = await Axios.post("calls-log/", { ...callsLogData }, config)
    const data = response.data
    localStorage.setItem("callsLog", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const changePassword = async passwordData => {
  try {
    const response = await Axios.post(
      "change-password/",
      { ...passwordData },
      config
    )
    const data = response.data
    localStorage.setItem("passwordData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const changeEmail = async emailData => {
  try {
    const response = await Axios.post("change-email/", { ...emailData }, config)
    const data = response.data
    localStorage.setItem("emailData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const updateProfile = async profileData => {
  try {
    const response = await Axios.post(
      "update-profile/",
      { ...profileData },
      config
    )
    const data = response.data
    localStorage.setItem("profileData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const userAccountParameters = async userAccountParametersData => {
  try {
    const response = await Axios.post(
      "account-parameters/",
      { ...userAccountParametersData },
      config
    )
    const data = response.data
    console.log(response)
    localStorage.setItem(
      "userAccountParametersData",
      JSON.stringify(data) || {}
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    console.log(error)
  }
}

export const searchRates = async searchRatesData => {
  try {
    const response = await Axios.post(
      "search-rates/",
      { ...searchRatesData },
      config
    )
    const data = response.data
    localStorage.setItem("searchRatesData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const systemSettings = async systemSettingsData => {
  try {
    const response = await Axios.post(
      "system-settings/",
      { ...systemSettingsData },
      config
    )
    const data = response.data
    localStorage.setItem("systemSettingsData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const verifyMobile = async verifyMobileData => {
  try {
    const response = await Axios.post(
      "verify-mobile-number/",
      { ...verifyMobileData },
      config
    )
    const data = response.data
    localStorage.setItem("verifyMobileData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const SignUp = async signUpData => {
  try {
    const response = await Axios.post("signup/", { ...signUpData }, config)
    const data = response.data

    localStorage.setItem("userData", JSON.stringify(data.responseData || {}))
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const SignIn = async signInData => {
  try {
    const response = await Axios.post("login/", { ...signInData }, config)

    const data = response.data
    const responseCode = data.responseCode
    if (responseCode === "00") {
      localStorage.setItem("userData", JSON.stringify(data.responseData || {}))
      console.log(data.responseData)
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    } else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const forgotPassword = async forgotPasswordData => {
  try {
    const response = await Axios.post(
      "forgotpassword/",
      { ...forgotPasswordData },
      config
    )
    const data = response.data
    // localStorage.setItem("forgotPasswordData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
  }
}

export const resetPassword = async resetPasswordData => {
  try {
    const response = await Axios.post(
      "resetpassword/",
      { ...resetPasswordData },
      config
    )
    const data = response.data
    // localStorage.setItem("resetPasswordData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const addCallerId = async addCallerIdData => {
  try {
    const response = await Axios.post(
      "add-callerId/",
      { ...addCallerIdData },
      config
    )
    const data = response.data
    // localStorage.setItem("addCallerIdData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const addCallerIdProcess = async addCallerIdProcessData => {
  try {
    const response = await Axios.post(
      "add-callerId-process/",
      { ...addCallerIdProcessData },
      config
    )
    const data = response.data
    // localStorage.setItem("addCallerIdProcessData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteCallerId = async deleteCallerIdData => {
  try {
    const response = await Axios.post(
      "delete-callerId/",
      { ...deleteCallerIdData },
      config
    )
    const data = response.data
    localStorage.setItem("deleteCallerIdData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const transferCredit = async transferCreditData => {
  try {
    const response = await Axios.post(
      "transfer-credit/",
      { ...transferCreditData },
      config
    )
    const data = response.data
    localStorage.setItem("transferCreditData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const existingBeneficiariesTransferCredit =
  async existingBeneficiariesTransferCreditData => {
    try {
      const response = await Axios.post(
        "existing-beneficiaries-transfer-credit/",
        { ...existingBeneficiariesTransferCreditData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "existingBeneficiariesTransferCreditData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }

export const transferCreditHistory = async transferCreditHistoryData => {
  try {
    const response = await Axios.post(
      "transfer-credit-history/",
      { ...transferCreditHistoryData },
      config
    )
    const data = response.data
    localStorage.setItem(
      "transferCreditHistoryData",
      JSON.stringify(data) || {}
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const topUpWithVoucher = async topUpWithVoucherData => {
  try {
    const response = await Axios.post(
      "top-up-with-voucher/",
      { ...topUpWithVoucherData },
      config
    )
    const data = response.data
    localStorage.setItem("topUpWithVoucherData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const topUpVoucherHistory = async topUpVoucherHistoryData => {
  try {
    const response = await Axios.post(
      "vouchers-history/",
      { ...topUpVoucherHistoryData },
      config
    )
    const data = response.data
    localStorage.setItem("topUpVoucherHistoryData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const showPin = async showPinData => {
  try {
    const response = await Axios.post("show-pin/", { ...showPinData }, config)
    const data = response.data
    localStorage.setItem("showPinData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const changePin = async changePinData => {
  try {
    const response = await Axios.post(
      "change-pin/",
      { ...changePinData },
      config
    )
    const data = response.data
    localStorage.setItem("changePinData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const authorizedCountries = async authorizedCountriesData => {
  try {
    const response = await Axios.post(
      "authorized-countries/",
      { ...authorizedCountriesData },
      config
    )
    const data = response.data
    localStorage.setItem("authorizedCountriesData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const listOfCountries = async listOfCountriesData => {
  try {
    const response = await Axios.post(
      "list-of-countries/",
      { ...listOfCountriesData },
      config
    )
    const data = response.data
    localStorage.setItem("listOfCountriesData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const addCountry = async addCountryData => {
  try {
    const response = await Axios.post(
      "add-country/",
      { ...addCountryData },
      config
    )
    const data = response.data
    localStorage.setItem("addCountryData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteCountry = async deleteCountryData => {
  try {
    const response = await Axios.post(
      "delete-country/",
      { ...deleteCountryData },
      config
    )
    const data = response.data
    localStorage.setItem("deleteCountryData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const contactCustomerService = async contactCustomerServiceData => {
  try {
    const response = await Axios.post(
      "contact-customer-service/",
      { ...contactCustomerServiceData },
      config
    )
    const data = response.data
    localStorage.setItem(
      "contactCustomerServiceData",
      JSON.stringify(data) || {}
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const availableFollowMeNumbers = async availableFollowMeNumbersData => {
  try {
    const response = await Axios.post(
      "available-follow-me-numbers/",
      { ...availableFollowMeNumbersData },
      config
    )
    const data = response.data
    localStorage.setItem(
      "availableFollowMeNumbersData",
      JSON.stringify(data) || {}
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const assignFollowMeNumber = async assignFollowMeNumberData => {
  try {
    const response = await Axios.post(
      "assign-follow-me-number/",
      { ...assignFollowMeNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("assignFollowMeNumberData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const currentFollowMeService = async currentFollowMeServiceData => {
  try {
    const response = await Axios.post(
      "current-follow-me-service/",
      { ...currentFollowMeServiceData },
      config
    )
    const data = response.data
    localStorage.setItem(
      "currentFollowMeServiceData",
      JSON.stringify(data) || {}
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const editFollowMeNumber = async editFollowMeNumberData => {
  try {
    const response = await Axios.post(
      "edit-follow-me-number/",
      { ...editFollowMeNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("editFollowMeNumberData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const canceFollowMeNumber = async canceFollowMeNumberData => {
  try {
    const response = await Axios.post(
      "cancel-follow-me-service/",
      { ...canceFollowMeNumberData },
      config
    )
    const data = response.data
    localStorage.setItem("canceFollowMeNumberData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const purchaseHistory = async purchaseHistoryData => {
  try {
    const response = await Axios.post(
      "purchase-history/",
      { ...purchaseHistoryData },
      config
    )
    const data = response.data
    localStorage.setItem("purchaseHistoryData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const buyCreditNewCard = async buyCreditNewCardData => {
  try {
    const response = await Axios.post(
      "buy-credit-new-card/",
      { ...buyCreditNewCardData },
      config
    )
    const data = response.data
    localStorage.setItem("buyCreditNewCardData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const storedCardsTokens = async storedCardsTokensData => {
  try {
    const response = await Axios.post(
      "stored-cards-tokens/",
      { ...storedCardsTokensData },
      config
    )
    const data = response.data
    localStorage.setItem("storedCardsTokensData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const buyCreditWithExistingCard =
  async buyCreditWithExistingCardData => {
    try {
      const response = await Axios.post(
        "buy-credit-with-existing-card/",
        { ...buyCreditWithExistingCardData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "buyCreditWithExistingCardData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }

export const deleteExistingCard = async deleteExistingCardData => {
  try {
    const response = await Axios.post(
      "delete-existing-card/",
      { ...deleteExistingCardData },
      config
    )
    const data = response.data
    localStorage.setItem("deleteExistingCardData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const updateExistingCard = async updateExistingCardData => {
  try {
    const response = await Axios.post(
      "update-existing-card/",
      { ...updateExistingCardData },
      config
    )
    const data = response.data
    localStorage.setItem("updateExistingCardData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const existingAutoRecharge = async existingAutoRechargeData => {
  try {
    const response = await Axios.post(
      "existing-auto-auto-recharge/",
      { ...existingAutoRechargeData },
      config
    )
    const data = response.data
    localStorage.setItem("existingAutoRechargeData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const setupAutoRecharge = async setupAutoRechargeData => {
  try {
    const response = await Axios.post(
      "setup-auto-recharge/",
      { ...setupAutoRechargeData },
      config
    )
    const data = response.data
    localStorage.setItem("setupAutoRechargeData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const updateExistingAutoRecharge =
  async updateExistingAutoRechargeData => {
    try {
      const response = await Axios.post(
        "update-existing-auto-recharge/",
        { ...updateExistingAutoRechargeData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "updateExistingAutoRechargeData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }

export const deleteExistingAutoRecharge =
  async deleteExistingAutoRechargeData => {
    try {
      const response = await Axios.post(
        "delete-existing-auto-recharge/",
        { ...deleteExistingAutoRechargeData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "deleteExistingAutoRechargeData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }

export const referAFriend = async referAFriendData => {
  try {
    const response = await Axios.post(
      "refer-a-friend/",
      { ...referAFriendData },
      config
    )
    const data = response.data
    localStorage.setItem("referAFriendData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const listReferredFriends = async listReferredFriendsData => {
  try {
    const response = await Axios.post(
      "list-referred-friends/",
      { ...listReferredFriendsData },
      config
    )
    const data = response.data
    localStorage.setItem("listReferredFriendsData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteReferredFriends = async deleteReferredFriendsData => {
  try {
    const response = await Axios.post(
      "delete-referred-friends/",
      { ...deleteReferredFriendsData },
      config
    )
    const data = response.data
    localStorage.setItem(
      "deleteReferredFriendsData",
      JSON.stringify(data) || {}
    )
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const becomeAPartner = async becomeAPartnerData => {
  try {
    const response = await Axios.post(
      "become-a-partner/",
      { ...becomeAPartnerData },
      config
    )
    const data = response.data
    localStorage.setItem("becomeAPartnerData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const deleteMyAccount = async deleteMyAccountData => {
  try {
    const response = await Axios.post(
      "delete-my-account/",
      { ...deleteMyAccountData },
      config
    )
    const data = response.data
    localStorage.setItem("deleteMyAccountData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const deleteMyAccountUsingControlCode =
  async deleteMyAccountUsingControlCodeData => {
    try {
      const response = await Axios.post(
        "delete-my-account-using-control-code/",
        { ...deleteMyAccountUsingControlCodeData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "deleteMyAccountUsingControlCodeData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }
export const buyCreditWithPayPal = async buyCreditWithPayPalData => {
  try {
    const response = await Axios.post(
      "buy-credit-ppal/",
      { ...buyCreditWithPayPalData },
      config
    )
    const data = response.data
    localStorage.setItem("buyCreditWithPayPalData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const getUserAccountParametersByToken =
  async getUserAccountParametersByTokenData => {
    try {
      const response = await Axios.post(
        "account-parameters-by-token/",
        { ...getUserAccountParametersByTokenData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "getUserAccountParametersByTokenData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }
export const userDashboardData = async userDashboardDataData => {
  try {
    const response = await Axios.post(
      "user-dashboard-data/",
      { ...userDashboardDataData },
      config
    )
    const data = response.data
    localStorage.setItem("userDashboardDataData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const enableOrDisableTwoFactorAuth =
  async enableOrDisableTwoFactorAuthData => {
    try {
      const response = await Axios.post(
        "enable-or-disable-two-factor-auth/",
        { ...enableOrDisableTwoFactorAuthData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "enableOrDisableTwoFactorAuthData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }
export const verifyTwoFactorAuth = async verifyTwoFactorAuthData => {
  try {
    const response = await Axios.post(
      "verify-two-factor-auth/",
      { ...verifyTwoFactorAuthData },
      config
    )
    const data = response.data
    localStorage.setItem("verifyTwoFactorAuthData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const sendOtp = async sendOtpData => {
  try {
    const response = await Axios.post("send-otp/", { ...sendOtpData }, config)
    const data = response.data
    localStorage.setItem("sendOtpData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
export const verifyOtp = async verifyOtpData => {
  try {
    const response = await Axios.post(
      "verify-otp/",
      { ...verifyOtpData },
      config
    )
    const data = response.data
    localStorage.setItem("verifyOtpData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

export const uploadProfilePictureOrCoverPhoto =
  async uploadProfilePictureOrCoverPhotoData => {
    try {
      const response = await Axios.post(
        "upload-profile-picture-or-cover-photo/",
        uploadProfilePictureOrCoverPhotoData,
        configMultiPart
      )
      const data = response.data
      localStorage.setItem(
        "uploadProfilePictureOrCoverPhotoData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      console.log(error.message)
      return { success: false, message: error.message }
    }
  }

export const updateSessionTimeoutDuration =
  async updateSessionTimeoutDurationData => {
    try {
      const response = await Axios.post(
        "update-session-timeout-duration/",
        { ...updateSessionTimeoutDurationData },
        config
      )
      const data = response.data
      localStorage.setItem(
        "updateSessionTimeoutDurationData",
        JSON.stringify(data) || {}
      )
      const responseCode = data.responseCode
      if (responseCode === "00")
        return {
          message: data.responseMessage,
          data: data.responseData,
          success: true,
        }
      else
        return {
          message: data.responseMessage,
          data: data.responseMessage,
          success: false,
        }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

export const resumeLogin = async resumeLoginData => {
  try {
    const response = await Axios.post(
      "resume-login/",
      { ...resumeLoginData },
      config
    )
    const data = response.data
    localStorage.setItem("resumeLoginData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}

// Admin Endpoints
export const listCustomers = async listCustomersData => {
  try {
    const response = await Axios.post(
      "list-customers/",
      { ...listCustomersData },
      config
    )
    console.log(response)
    const data = response.data
    localStorage.setItem("listCustomersData", JSON.stringify(data) || {})
    const responseCode = data.responseCode
    if (responseCode === "00")
      return {
        message: data.responseMessage,
        data: data.responseData,
        success: true,
      }
    else
      return {
        message: data.responseMessage,
        data: data.responseMessage,
        success: false,
      }
  } catch (error) {
    console.log(error.message)
    return { success: false, message: error.message }
  }
}
