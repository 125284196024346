import PropTypes from "prop-types"
import React, { Component } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
import { Button, Collapse } from "reactstrap"
import externalLinks from "../../helpers/externalLinks"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  logout() {
    console.log("Logging Out")
    localStorage.removeItem("userData")
    window.location.href = externalLinks.vomozFlexLandingPage
    return null
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {/* Section One */}
                  <li className="menu-title">{this.props.t("Menu")}</li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/dashboard">
                      <i className="bx bx-home-circle mr-2" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                    </Link>
                  </li>
                  {/* Section Two */}
                  <li className="menu-title">{this.props.t("Account")}</li>
                  <li className="nav-item">
                    <Link to="/account/auto-top-up" className="nav-link =">
                      <i className="bx bx-purchase-tag mr-2" />
                      {this.props.t("Auto Top Up")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/buy-credit" className="nav-link =">
                      <i className="bx bx-credit-card mr-2" />
                      {this.props.t("Buy Call Credit")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/transfer-credit" className="nav-link =">
                      <i className="bx bx-transfer mr-2" />
                      {this.props.t("Transfer Call Credit")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/vomoz-card" className="nav-link =">
                      <i className="bx bx-gift mr-2" />
                      {this.props.t("Load Vomoz Card")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/call-log" className="nav-link =">
                      <i className="bx bx-history mr-2" />
                      {this.props.t("Call Log")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/local-numbers" className="nav-link =">
                      <i className="bx bxs-contact mr-2" />
                      {this.props.t("Local Numbers")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/follow-me" className="nav-link =">
                      <i className="bx bx-map-pin mr-2" />
                      {this.props.t("Follow Me Service")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/caller-id" className="nav-link =">
                      <i className="bx bx-user mr-2" />
                      {this.props.t("Caller IDs")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account/access-numbers" className="nav-link =">
                      <i className="bx bx-phone mr-2" />
                      {this.props.t("Access Numbers")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/account/authorized-countries"
                      className="nav-link ="
                    >
                      <i className="bx bx-globe mr-2" />
                      {this.props.t("Authorized Countires")}
                    </Link>
                  </li>
                  {/* Section Three */}
                  <li className="menu-title">{this.props.t("Services")}</li>
                  <li className="nav-item">
                    <Link to="/services/rates" className="nav-link =">
                      <i className="bx bx-user-circle mr-2" />
                      {this.props.t("Rates")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/contact" className="nav-link =">
                      <i className="bx bx-support mr-2" />
                      {this.props.t("Contact Us")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/referral" className="nav-link =">
                      <i className="bx bx-plus-circle mr-2" />
                      {this.props.t("Refer A Friend")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/partner" className="nav-link =">
                      <i className="bx bx-file mr-2" />
                      {this.props.t("Become A Partner")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/social" className="nav-link =">
                      <i className="bx bx-share-alt mr-2" />
                      {this.props.t("Follow Us")}
                    </Link>
                  </li>
                  {/* Section Four */}
                  <li className="menu-title">{this.props.t("Settings")}</li>
                  <li className="nav-item">
                    <Link to="/settings/user-settings" className="nav-link =">
                      <i className="bx bx-wrench mr-2" />
                      {this.props.t("User Settings")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/settings/show-pin" className="nav-link =">
                      <i className="bx bx-lock mr-2" />
                      {this.props.t("Show PIN")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/settings/profile-photos" className="nav-link =">
                      <i className="bx bx-camera mr-2" />
                      {this.props.t("Profile Photos")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/settings/session-timeout" className="nav-link =">
                      <i className="bx bx-timer mr-2" />
                      {this.props.t("Session Timeout")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/settings/delete-account" className="nav-link =">
                      <i className="bx bx-trash mr-2" />
                      {this.props.t("Delete Account")}
                    </Link>
                  </li>{" "}
                  <li>
                    <Button
                      style={{
                        backgroundColor: "#fff",
                        borderStyle: "none",
                      }}
                      onClick={this.logout}
                      className="nav-link ="
                    >
                      <i className="bx bx-power-off font-size-16 align-middle pr-2 mr-0 text-danger" />
                      <span style={{ color: "#545A6D" }}>{"Logout"}</span>
                    </Button>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
