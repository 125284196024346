import "assets/scss/datatables.scss"
import classnames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import Email from "./components/Email/email"
import Security from "./components/Password/password"
import Pin from "./components/PIN/pin"
import UpdateProfile from "./components/Profile/UpdateProfile"
import TwoFactorAuth from "./components/TwoFactorAuth/TwoFactorAuth"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const UserSettings = () => {
  const [loading, setLoading] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const [userEmail, setUserEmail] = useState(null)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const getUserParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) setUserEmail(userParameters.email)
  }
  useEffect(() => {
    getUserParameters()
  }, [])
  console.log(userEmail)

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          <Breadcrumbs title="Update" breadcrumbItem="Settings" />

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col lg="6">
              <Card>
                <CardBody className="px-2">
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Email
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        PIN
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5")
                        }}
                      >
                        2-Factor Authentication
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleTab("6")
                        }}
                      >
                        Profile Photos
                      </NavLink>
                    </NavItem> */}
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="email">
                      <Email />
                    </TabPane>
                    <TabPane tabId="2" id="security">
                      <Security />
                    </TabPane>
                    <TabPane tabId="3" id="pin">
                      <Pin />
                    </TabPane>
                    <TabPane tabId="4" id="profile">
                      <UpdateProfile />
                    </TabPane>
                    <TabPane tabId="5" id="2fa">
                      <TwoFactorAuth />
                    </TabPane>
                    {/* <TabPane tabId="6" id="picture">
                      <ProfilePicture />
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserSettings.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(UserSettings)
