import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { ORG_ID, USER } from "helpers/constants"
import { maskString } from "helpers/helperFunctions"
import { sendOtp, verifyOtp } from "helpers/requests"
import React, { useEffect, useRef, useState } from "react"
import AuthCode from "react-auth-code-input"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap"
import logo from "../../assets/images/logo.svg"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const userGlobalId = user?.userGlobalId
const userCallerID = user?.callerIdList
const phoneCallerId = localStorage.getItem("phoneCallerId")

const VerifyOTP = () => {
  const history = useHistory()
  const authCodeRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(null)
  const [code, setCode] = useState("")
  const [resendLoading, setResendLoading] = useState(false)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })
  const isDataAvailable = USER && userCallerID && userCallerID.length > 0
  const callerId =
    userCallerID && userCallerID.length > 0
      ? userCallerID.length > 1
        ? phoneCallerId
        : userCallerID[0]?.callerId
      : null

  useEffect(() => {
    if (user && userCallerID && userCallerID.length > 0) {
      setLoading(false) // Stop loading when phone numbers are available
    }
  }, [user, userCallerID])

  const handleChange = value => setCode(value)

  useEffect(() => {
    if (code.length === 6) {
      handleSubmit()
    }
  }, [code])

  const handleSubmit = async () => {
    if (isDataAvailable) {
      setLoading2(true)

      if (!callerId) {
        setLoading2(null)
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Error",
          dynamic_description: "Caller ID is missing. Kindly refresh the page.",
        })
        return
      }

      const data = {
        orgId: ORG_ID,
        userGlobalId,
        callerId,
        otpCode: code,
      }

      const res = await verifyOtp(data)
      if (res === undefined) {
        setLoading2(null)
        alert("Please Check your internet connection")
      } else if (res?.success === false) {
        setLoading2(null)
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Failed!",
          dynamic_description: res?.message,
        })
      } else if (res?.success === true) {
        setLoading2(null)
        setInfo({
          confirm: false,
          success_dlg: true,
          dynamic_title: "Verified!",
          dynamic_description: res?.message,
        })
        setTimeout(() => {
          history.push("/dashboard")
        }, 3000)
      }
    }
  }

  const handleResendOtp = async () => {
    if (isDataAvailable) {
      setResendLoading(true)
      const callerId =
        userCallerID && userCallerID.length > 0
          ? userCallerID.length > 1
            ? phoneCallerId
            : userCallerID[0]?.callerId
          : null

      const data = {
        orgId: ORG_ID,
        userGlobalId,
        callerId,
      }

      try {
        const res = await sendOtp(data)
        if (res?.success === true) {
          setInfo({
            confirm: false,
            success_dlg: true,
            dynamic_title: "OTP Resent",
            dynamic_description:
              "A new OTP has been sent to your phone number.",
          })
          setCode("")
          if (authCodeRef.current) {
            authCodeRef.current.clear()
          }
        } else {
          setInfo({
            confirm: false,
            error_dlg: true,
            dynamic_title: "Error",
            dynamic_description: res?.message || "Failed to resend OTP",
          })
        }
      } catch (error) {
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Error",
          dynamic_description: "An error occurred while resending OTP.",
        })
      } finally {
        setResendLoading(false)
      }
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          showConfirm={false}
          onConfirm={() => setInfo({ confirm: false })}
        >
          <img className="spinning loading-logo" src={logo} />
        </SweetAlert>
      )}
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-4 pt-sm-4">
        <Container
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ minHeight: "100vh" }}
        >
          <Card className="custom-card shadow-lg rounded-lg">
            <CardBody>
              <div className="p-2">
                <div className="text-center">
                  <div className="avatar-md mx-auto">
                    <div className="avatar-title rounded-circle bg-light">
                      <i className="bx bxs-message h1 mb-0 text-primary"></i>
                    </div>
                  </div>
                  <div className="p-2 mt-3">
                    <h4>Verify OTP</h4>
                    <p className="mb-5">
                      {`Enter the 6-digit code sent to your phone number (${maskString(
                        callerId
                      )})`}
                      .
                    </p>

                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit()
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <FormGroup className="verification">
                            <label htmlFor="digit1-input" className="sr-only">
                              2FA Code
                            </label>
                            <AuthCode
                              ref={authCodeRef}
                              length={6}
                              inputClassName="authCodeInput text-center"
                              onChange={handleChange}
                              allowedCharacters="numeric"
                            />
                          </FormGroup>
                          <div className="mt-2">
                            <Button
                              className="btn btn-block btn-success w-md"
                              type="submit"
                              disabled={code.length !== 6}
                            >
                              {loading2 ? "Verifying..." : "Verify"}
                            </Button>
                          </div>
                          <div className="mt-4">
                            <p>
                              It may take a few seconds (or more) to get your
                              code. Check your phone message inbox to receive
                              it.
                            </p>
                          </div>
                          <div className="text-center mt-3">
                            <Button
                              color="link"
                              onClick={handleResendOtp}
                              disabled={resendLoading}
                            >
                              {resendLoading ? "Resending..." : "Resend OTP"}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="mt-4 text-center">
            <p>
              Didn't receive a code?{" "}
              <Link to="/login">
                <span className="font-weight-medium text-primary">
                  {" "}
                  Back to Login{" "}
                </span>{" "}
              </Link>
            </p>
            <FooterMessage />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VerifyOTP
