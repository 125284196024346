import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { userAccountParameters } from "../../../../helpers/requests"
import TwoFactorAuthModal from "./TwoFactorAuthModal"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const TwoFactorAuth = () => {
  const [open, setOpen] = useState(false)
  const [twoFAStatus, setTwoFAStatus] = useState("")
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    error_dlg: null,
  })

  const getUserParameters = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    console.log("userParameters", userParameters)
    if (userParameters) {
      setTwoFAStatus(userParameters.twoFactorAuthStatus)
      setLoad(true)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  const toggleModal = () => {
    setInfo({
      confirm: false,
    })
    setOpen(!open)
  }

  const turn2FAToOpposite = word => (word === "enabled" ? "disable" : "enable")
  const oppositeOf2FAStatus = turn2FAToOpposite(twoFAStatus)

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        {load ? (
          <TwoFactorAuthModal
            isOpen={open}
            toggle={toggleModal}
            oppositeOf2FAStatus={oppositeOf2FAStatus}
            refreshData={getUserParameters}
          />
        ) : null}
        <div className="bg-primary">
          <Row>
            <Col>
              <div className="text-white p-4">
                <p>2-Factor Authentication (2FA)</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-2 px-0">
          <div className="pt-3 mb-5">
            <p style={{ fontSize: "14px" }}>
              2FA status:{" "}
              <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {twoFAStatus}
              </span>
            </p>
          </div>
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {/* <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={() => setInfo({ confirm: true })}
              style={{ width: "45%", textTransform: "capitalize" }}
            >
              {oppositeOf2FAStatus} 2FA
            </button> */}

            <Button style={{ width: "45%" }} type="button" color="link">
              <Link to="/">Cancel</Link>
            </Button>
          </div>
          <div className="mb-2">
            {info.confirm && (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="success"
                cancelBtnText="No"
                cancelBtnBsStyle="danger"
                onConfirm={toggleModal}
                onCancel={() =>
                  setInfo({
                    error_dlg: true,
                    dynamic_title: "Cancelled",
                    dynamic_description: "Process has been terminated!",
                  })
                }
              >
                Your 2FA Status will be modified if you proceed!
              </SweetAlert>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

TwoFactorAuth.propTypes = {
  t: PropTypes.any,
}

export default TwoFactorAuth
