import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { ErrorMessage, Form, Formik } from "formik"
import { ORG_ID } from "helpers/constants"
import externalLinks from "helpers/externalLinks"
import { deleteMyAccountUsingControlCode } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import logoImg from "../../assets/images/logo.svg"
import profileImg from "../../assets/images/profile-img.png"
import "./styles.scss"

const DeleteMyAccount = () => {
  const [loading, setLoading] = useState(null)
  const [loading2, setLoading2] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [charCount, setCharCount] = useState(0)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })

  const controlCodeObject = new URLSearchParams(window.location.search)
  const controlCode = controlCodeObject.get("d")

  useEffect(() => {
    setLoading(true)
  }, [])

  const validateForm = reason => {
    if (reason && reason.length > 300) {
      setErrorMessage("Reason must be 300 characters or less.")
      return false
    }
    setErrorMessage("")
    return true
  }

  const handleDeleteClick = values => {
    if (validateForm(values.reason)) {
      setInfo({ confirm: true })
    }
  }

  const handleSubmit = async (values, setFieldValue) => {
    setInfo({ confirm: false })
    setLoading2(true)
    const data = {
      orgId: ORG_ID,
      reason: values.reason,
      controlCode,
    }

    const response = await deleteMyAccountUsingControlCode(data)

    if (response === undefined) {
      alert("Please check your internet connection")
      setLoading2(false)
    } else if (response?.success) {
      setLoading2(false)
      setFieldValue("reason", "")
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Success!",
        dynamic_description: response?.message,
      })
    } else {
      setLoading2(false)
      setFieldValue("reason", "")
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: response?.message,
      })
    }
  }

  const handleReturnHome = () =>
    (window.location.href = externalLinks.vomozFlexLandingPage)

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          timeout={1000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={logoImg} />
        </SweetAlert>
      )}
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() =>
            (window.location.href = externalLinks.vomozFlexLandingPage)
          }
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      <Container className="d-flex justify-content-center align-items-center flex-column full-vh">
        <Card className="custom-card shadow-lg rounded-lg">
          <div className="bg-soft-primary">
            <Row>
              <Col xs="7">
                <div className="text-primary p-4">
                  <h5 className="text-primary">We're Sorry to See You Go</h5>
                  <p>
                    Please share why you're leaving and confirm your account
                    deletion below.
                  </p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody>
            <div>
              <a href={externalLinks.vomozFlexLandingPage}>
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logoImg}
                      alt=""
                      className="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </a>
            </div>
            <div className="p-0">
              <div className="text-center">
                <div className="p-2 mt-3">
                  <Formik
                    initialValues={{ reason: "" }}
                    validationSchema={Yup.object({
                      reason: Yup.string().max(
                        300,
                        "Must be 300 characters or less"
                      ),
                    })}
                    onSubmit={values => handleDeleteClick(values)}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col xs={12}>
                            {errorMessage && (
                              <Alert color="danger">{errorMessage}</Alert>
                            )}
                            <FormGroup className="verification">
                              <Label className="label-text" for="reason">
                                Reason for Account Deletion
                              </Label>
                              <Input
                                type="textarea"
                                name="reason"
                                id="reason"
                                placeholder="Please let us know your reason for leaving..."
                                value={values.reason}
                                onChange={e => {
                                  const inputValue = e.target.value
                                  if (inputValue.length <= 300) {
                                    setFieldValue("reason", inputValue)
                                    setCharCount(inputValue.length)
                                    if (errorMessage) {
                                      setErrorMessage("")
                                    }
                                  } else {
                                    setErrorMessage(
                                      "You have exceeded the character limit"
                                    )
                                  }
                                }}
                                rows="5"
                              />
                              <ErrorMessage
                                name="reason"
                                component="div"
                                className="text-danger"
                              />
                              <div className="char-limit text-muted mt-1">
                                {charCount}/300
                              </div>
                            </FormGroup>
                            <div className="mt-2">
                              <Button
                                color="danger"
                                className="btn btn-block waves-effect waves-light mt-2"
                                type="button"
                                onClick={handleDeleteClick}
                              >
                                {loading2 ? "Deleting..." : "Delete My Account"}
                              </Button>
                            </div>

                            <div className="text-center mt-3">
                              <Button color="link" onClick={handleReturnHome}>
                                {"Take Me Home"}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        {info.confirm && (
                          <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            confirmBtnBsStyle="success"
                            cancelBtnText="No"
                            cancelBtnBsStyle="danger"
                            onConfirm={() =>
                              handleSubmit(values, setFieldValue)
                            }
                            onCancel={() => setInfo({ confirm: false })}
                          >
                            You won't be able to revert this!
                          </SweetAlert>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="mt-4 text-center">
          <p>
            Changed your mind?{" "}
            <Link to="/login">
              <span className="font-weight-medium text-primary">
                {" "}
                Back to Login{" "}
              </span>{" "}
            </Link>
          </p>
          <FooterMessage />
        </div>
      </Container>
    </React.Fragment>
  )
}

export default DeleteMyAccount
